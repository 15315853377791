import React, {  useState ,useEffect} from 'react';
import Swal from 'sweetalert2';
import style from '../../WebsiteSettings/CompanyGoals.module.css';
import { useNavigate, useLocation,useParams } from 'react-router';
import { basename } from '../../../config/config';
import { editAdvantage, getAdvantageDetail } from '../../../api/pagesApi';

export default function EditAdvantage() {
    const [editedBenefit, seteditedBenefit] = useState({ title: '', description: '' });
    const [errors, setErrors] = useState({ })
    const [loading, setLoading] = useState(false);
    const { pathname } = useLocation();
    let { slug } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    let navigate = new useNavigate();

    useEffect(()=>{
        getAdvantageDetail(slug).then((res)=>{
        let benefit = { ...editedBenefit }
        benefit= res.data.data
        seteditedBenefit(benefit)
        })
        //eslint-disable-next-line
    },[])
    function handleBenefitChange(e) {
        let myeditedBenefit = { ...editedBenefit };
        myeditedBenefit[e.target.name] = e.target.value;
        seteditedBenefit(myeditedBenefit);

        let err = { ...errors }
        err[e.target.name] = '';
        setErrors(err);
    }

    function editBenefitSubmit(e) {
        e.preventDefault();
        setLoading(true);
        editAdvantage(slug,editedBenefit).then(() => {
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    text: "تم تعديل البيانات بنجاح",
                    showConfirmButton: false,
                    showCancelButton: false,
                    timer: 2000
                }).then(() => {
                    navigate(`${basename}/dashboard/pages/about-us`);
                })
            }).catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: 'error',
                    timer: 2500,
                    text: "عفوا لقد حدث خطأ!",
                    showConfirmButton: false,
                    showCancelButton: false
                }).then(()=>{
                    let err = { ...errors }
                    Object.entries(error.response.data.data).map(([key, value]) => {
                        err[key] = value;
                        setErrors(err);
                        return  true;
                    });
                })
            })
    }


  return (
    <section style={{ "direction": "rtl" }}>
    <div className='sectionWrapper'>
        <h4>  تعديل الميزة</h4>
        <form id="benefitFormId" onSubmit={editBenefitSubmit}>
            <div className={` p-3`}>
                {/* title */}
                <div className="row mb-4">
                    <div className="col-lg-6 col-12">
                        <div className="form-outline">
                            <label className="form-label labels" htmlFor="title"> العنوان <span className='languages'>(عربي)</span></label>
                            <input value={editedBenefit ? editedBenefit.title : ''} onChange={handleBenefitChange} type="text" name="title" id="title" className="form-control createInputs" required />
                        </div>
                        <div className='text-danger mt-1'>{errors.title}</div>
                    </div>
                </div>

                {/* short descriptions */}
                <div className="row mb-4">
                    <div className="col-lg-6 col-12">
                        <div className="form-outline">
                            <label className="form-label labels" htmlFor="description">الوصف <span className='languages'>(عربي)</span></label>
                            <textarea value={editedBenefit ? editedBenefit.description : ''} onChange={handleBenefitChange} name='description' id="description" className={`form-control textArea textAreaCreate createInputs ${style.textAreaModal}`} minLength='8' required />
                        </div>
                        <div className='text-danger mt-1'>{errors.description}</div>
                    </div>
                </div>
            </div>
            <div className={` p-3`}>
                {!loading ?
                    <input className='createBtnContainer mb-2 py-1' type='submit' value='تعديل' />
                    :
                    <button className='createBtnContainer mb-2  py-1' disabled>
                        جاري الحفظ
                        <span className="spinner-border text-light spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    </button>
                }
            </div>
        </form>
    </div>
</section>
  )
}
