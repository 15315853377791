import React, { useEffect, useState } from 'react';
import moment from 'moment';
import style from "./Types.module.css";
import Swal  from 'sweetalert2';
import { getallTypes, addType, editType, deleteType } from './../../api/type';

export default function Types() {
    let [projectsTypes , setprojectsTypes] = useState([]);
    /** add type **/
    let [newType , setnewType] = useState({name: "" });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ name: ''});
    /** edit type **/
    let [type , settype]= useState({});

    function getProjectsTypes() {
      getallTypes().then(res => {
          setprojectsTypes(res.data.data);
        })
    }
    
    useEffect(()=>{
      getProjectsTypes();
    },[]);

    /****************** add new project type ********************/
    // add new project handler
    function addNewType(e){
      let mynewType = {...newType};
      setErrors({...errors , [e.target.name]: ""});
      mynewType[e.target.name] = e.target.value;
      setnewType(mynewType);
    }

    // add new project type submit
    function submitNewProjectType(e){
      e.preventDefault();
      setLoading(true);
      if(newType.name !== ''){
        addType(newType).then(res => {
          setLoading(false);
            Swal.fire({
                icon: 'success',
                text: "تمت اضافة هذا النوع بنجاح",
                showConfirmButton: false,
                timer: 2000,
                iconColor: "#F7A116",
                /* confirmButtonColor : "#1f2937" */
            });
            getProjectsTypes();
            document.getElementById('formId').reset();
            let myModal = document.getElementById('closeAddTypeModal');
            myModal.click();
        })
        .catch(error => {
          setLoading(false);
          let err = { ...errors }
            for (const key in err) {
              if (error.response.data.data[key]) {
                err = { ...err,[key]: error.response.data.data[key] };
                setErrors(err);
              }
            }
          Swal.fire({
              icon: 'error',
              timer: 3000,
              text: "عفوا حدث خطأ",
              confirmButtonColor : "#1f2937"
          });
          
        })
        } 
    }

    /******************** edit project type  *******************/
    // get project type
    function getProjectType(t){
      settype(t);
    }
    // edit project type handler
    function editTypeHandler(e){
      let mytype = {...type};
      setErrors({...errors , [e.target.name]: ""});
      mytype[e.target.name] = e.target.value;
      settype(mytype);
    }
    // edit project type submit
    function submitEditedProjectType(e){
      e.preventDefault();
      setLoading(true);
      let slug = type.slug;   
      if(type.name !== ''){
        editType(slug,type).then(res => {
          setLoading(false);
            Swal.fire({
                icon: 'success',
                text: "تم تعديل نوع المشروع بنجاح",
                timer: 2000,
                iconColor: "#F7A116"
            });
            getProjectsTypes();
            document.getElementById('formIdEdit').reset();
            let myModal = document.getElementById('closeEditTypeModal');
            myModal.click();
        })
        .catch(error => {
          setLoading(false);
          let err = { ...errors }
          for (const key in err) {
            if (error.response.data.data[key]) {
              err = { ...err, [key]: error.response.data.data[key] };
              setErrors(err);
            }
          }
          Swal.fire({
              icon: 'error',
              timer: 2500,
              text: "عفوا حدث خطأ",
              confirmButtonColor : "#1f2937"
          })
        })
        } 
    }

    /****************** delete project type  ******************/
    function deleteProjectTypeSubmit(t){
      let slug = t.slug;

      Swal.fire({
        title: 'هل انت متأكد ؟',
        text: "لا يمكن التراجع عن هذا الأمر",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F7A116',
        cancelButtonColor: '#1f2937',
        confirmButtonText: 'نعم, احذف ',
        cancelButtonText: 'إلغاء'
      }).then((result) => {
        if (result.isConfirmed) {
        deleteType(slug).then(res => {
            Swal.fire({
                icon: 'success',
                text: "تم حذف المنطقة بنجاح",
                timer: 2000,
                iconColor: "#F7A116"
            });
            getProjectsTypes();
        })
        .catch(err => {
            Swal.fire({
              icon: 'error',
              text: err.response.data.message,
              confirmButtonText:'تم'
            }).then(()=>{
              getProjectsTypes();
          })
        })
        }
      })
    }


  return (
    <>
      <section style={{"direction" : "rtl"}}>
    
          <div className='sectionWrapper'>
            <button className='createBtnContainer' data-bs-toggle="modal" data-bs-target="#addProjectCategory">
              اضافة نوع مشروع
            </button>
            {/** displaying data in table **/}
            {projectsTypes && projectsTypes.length > 0 ?
                  <div className='table-responsive'>

            <table className={`${style.types_table} table table-striped`} dir='rtl'>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">نوع المشروع</th>
                  <th scope="col">التاريخ</th>
                  <th scope="col">تعديل</th>
                  <th scope="col">حذف</th>
                </tr>
              </thead>
              <tbody className={`${style.noBorder}`}>
                {projectsTypes && projectsTypes.map((type , index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{type.name}</td>
                  <td>{moment(type.created_at).format('DD/MM/YYYY')}</td>
                  {/** edit icon **/}
                  <td>
                    <div onClick={()=> getProjectType(type)} data-bs-toggle="modal" data-bs-target='#editProjectType' className={`${style.brandsIcon}`}>
                      <svg width="17" height="17" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.1378 2.98637C14.7152 3.56378 14.7247 4.49698 14.1591 5.08597L8.74224 10.7271C8.46695 11.0137 8.08913 11.1795 7.69176 11.1878L5.41644 11.2355L5.46449 8.94497C5.47264 8.55664 5.63111 8.1866 5.90655 7.91274L11.4436 2.40735C12.0298 1.82442 12.9773 1.82578 13.5619 2.41038L14.1378 2.98637Z" fill="#003260"/>
                          <path opacity="0.3" d="M8.60065 1.33325C8.96884 1.33325 9.26732 1.63173 9.26732 1.99992C9.26732 2.36811 8.96884 2.66659 8.60065 2.66659H4.00065C3.26427 2.66659 2.66732 3.26354 2.66732 3.99992V11.9999C2.66732 12.7363 3.26427 13.3333 4.00065 13.3333H12.0007C12.737 13.3333 13.334 12.7363 13.334 11.9999V8.66659C13.334 8.2984 13.6325 7.99992 14.0007 7.99992C14.3688 7.99992 14.6673 8.2984 14.6673 8.66659V11.9999C14.6673 13.4727 13.4734 14.6666 12.0007 14.6666H4.00065C2.52789 14.6666 1.33398 13.4727 1.33398 11.9999V3.99992C1.33398 2.52716 2.52789 1.33325 4.00065 1.33325H8.60065Z" fill="#1B3C94"/>
                      </svg>
                    </div>
                  </td>
                  {/** delete icon **/}
                  <td>
                    <div onClick={()=> deleteProjectTypeSubmit(type)} className={`${style.brandsIcon}`}>
                      <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21.0697 5.23C19.4597 5.07 17.8497 4.95 16.2297 4.86V4.85L16.0097 3.55C15.8597 2.63 15.6397 1.25 13.2997 1.25H10.6797C8.34967 1.25 8.12967 2.57 7.96967 3.54L7.75967 4.82C6.82967 4.88 5.89967 4.94 4.96967 5.03L2.92967 5.23C2.50967 5.27 2.20967 5.64 2.24967 6.05C2.28967 6.46 2.64967 6.76 3.06967 6.72L5.10967 6.52C10.3497 6 15.6297 6.2 20.9297 6.73C20.9597 6.73 20.9797 6.73 21.0097 6.73C21.3897 6.73 21.7197 6.44 21.7597 6.05C21.7897 5.64 21.4897 5.27 21.0697 5.23Z" fill="#02315B"/>
                      <path d="M19.2297 8.14C18.9897 7.89 18.6597 7.75 18.3197 7.75H5.67975C5.33975 7.75 4.99975 7.89 4.76975 8.14C4.53975 8.39 4.40975 8.73 4.42975 9.08L5.04975 19.34C5.15975 20.86 5.29975 22.76 8.78975 22.76H15.2097C18.6997 22.76 18.8398 20.87 18.9497 19.34L19.5697 9.09C19.5897 8.73 19.4597 8.39 19.2297 8.14ZM13.6597 17.75H10.3297C9.91975 17.75 9.57975 17.41 9.57975 17C9.57975 16.59 9.91975 16.25 10.3297 16.25H13.6597C14.0697 16.25 14.4097 16.59 14.4097 17C14.4097 17.41 14.0697 17.75 13.6597 17.75ZM14.4997 13.75H9.49975C9.08975 13.75 8.74975 13.41 8.74975 13C8.74975 12.59 9.08975 12.25 9.49975 12.25H14.4997C14.9097 12.25 15.2497 12.59 15.2497 13C15.2497 13.41 14.9097 13.75 14.4997 13.75Z" fill="#02315B"/>
                      </svg>
                    </div>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
</div>
            :<div className='noDataClass d-flex justify-content-center align-items-center'>لا توجد بيانات</div>
            }
          </div>
          {/** add new project type modal **/}
          <div className="modal fade" id="addProjectCategory" tabIndex={-1} aria-labelledby="newProjectTypeLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className={`modal-title ${style.newType_modal}`} id="newProjectTypeLabel">اضافة نوع مشروعات</h5>                  
                  <button id="closeAddTypeModal" type="button" className="btn-close m-0" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <form id="formId" onSubmit={submitNewProjectType}>
                    <div className="modal-body">
                      <div className='d-flex flex-column justify-content-between'>
                        <div className='mb-3'>
                          <input autoComplete='off' type='text' required onChange={addNewType} name='name' className={`${style.modalInputStyle} border-0 w-100`} placeholder='ادخل الاسم باللغة العربية' />
                          <div className='text-danger'>{errors.name}</div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer border-top-0" dir='rtl'>
                      {!loading ?
                          <input className='createBtnContainer mb-2 w-100 py-1' type='submit' value='حفظ'/>
                      :
                          <button  className='createBtnContainer mb-2 w-100 py-1' disabled>
                              جاري الحفظ
                              <span className="spinner-border text-light spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                          </button>
                      }
                    </div>
                </form>
              </div>
            </div>
          </div>

          {/** edit project type modal **/}
          <div className="modal fade" id={`editProjectType`} tabIndex={-1} aria-labelledby="editProjectTypeLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className={`modal-title ${style.newType_modal}`} id="editProjectTypeLabel">اضافة نوع مشروع</h5>                  
                  <button id="closeEditTypeModal" type="button" className="btn-close m-0" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                {type && 
                  <form id="formIdEdit" onSubmit={submitEditedProjectType}>
                      <div className="modal-body">
                        <div className='d-flex flex-column justify-content-between'>
                          <div className='mb-3'>
                            <input onChange={editTypeHandler} value={type.name} autoComplete='off' type='text' name='name' className={`${style.modalInputStyle} border-0 w-100`} placeholder='ادخل الاسم باللغة العربية' required/>
                            <div className='text-danger'>{errors.name}</div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer" dir='rtl'>
                        {!loading ?
                            <input className='createBtnContainer mb-2 w-100 py-1' type='submit' value='تعديل'/>
                        :
                            <button  className='createBtnContainer mb-2 w-100 py-1' disabled>
                                جاري الحفظ
                                <span className="spinner-border text-light spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            </button>
                        }
                      </div>
                  </form>
                }
              </div>
            </div>
          </div>

          {/** delete project type modal **/}
          <div className="modal fade" id='deleteProjectType' tabIndex={-1} aria-labelledby="deleteProjectTypeLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className={`modal-content ${style.block_modal_content}`}>
                    <div className={`modal-header border-bottom-0 ${style.block_modal_header}`}>
                        <h5 className="modal-title" id='deleteProjectTypeLabel'>هل تريد حذف نوع المشروع ؟</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className={`modal-footer border-top-0 pt-0 w-100 ${style.block_modal_footer}`}>
                        <button type="button" className={`${style.cancelBtn}`} data-bs-dismiss="modal">الغاء</button>
                        <button onClick={deleteProjectTypeSubmit} type="submit" className={`${style.blockBtn}`} data-bs-dismiss="modal">تنفيذ</button>
                    </div>
                </div>
            </div>
          </div>    
      </section>
    </>
  )
}
