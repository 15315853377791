import React from "react";
import { useState } from "react";
import  Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { userCheckEmail, userResetPassword } from "../../api/authApi";
import { basename } from './../../config/config';

export default function ResetPassword() {
  let [loading, setLoading] = useState(false);
  let [emailSent, setemailSent] = useState(false);
  let [resetEmail, setResetEmail] = useState({ email: "" });
  let [resetPasswords, setresetPassword] = useState({});
  let [resetErrors, setresetErrors] = useState({ email: "" });
  let [confirmErrors, setconfirmErrors] = useState({
    pin_code: "",
    password: "",
    password_confirmation: "",
  });
  let navigate = useNavigate();

  function resetpasswordHandler(e) {
    let emailReset = { ...resetEmail };
    let resetPassword = { ...resetPasswords };
    let resetError = { ...resetErrors };
    let confirmError = { ...confirmErrors };
    if (e.target.name === "email") {
      emailReset[e.target.name] = e.target.value;
      resetError[e.target.name] = "";
      setResetEmail(emailReset);
      setresetErrors(resetError);
    } else {
        confirmError[e.target.name] = "";
      if(e.target.name === 'pin_code'){
        resetPassword[e.target.name] = Number(e.target.value);
      }else{
        resetPassword[e.target.name] = e.target.value;
      }
      setresetPassword(resetPassword);
      setconfirmErrors(confirmError);
    }
  }

  function sendingEmail(e) {
    e.preventDefault();
    setLoading(true);
    userCheckEmail(resetEmail).then(() => {
        setLoading(false);
        setemailSent(true);
      })
      .catch((error) => {
        setLoading(false);
        let err = { ...resetErrors };
        for (const key in err) {
          if (error.response.data.data[key]) {
            err = { ...err, [key]: error.response.data.data[key] };
            setresetErrors(err);
          }
        }
      });
  }

  function confirmPassword(e) {
    e.preventDefault();
    setLoading(true);
    userResetPassword(resetPasswords).then(() => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Password reset successfully",
          timer: 3000,
        }).then(() => {
          navigate(`${basename}/`);
        });
      }).catch((error) => {
        setLoading(false);
        let err = { ...confirmErrors };
        for (const key in err) {
          if (error.response.data.data[key]) {
            err = { ...err, [key]: error.response.data.data[key] };
            setconfirmErrors(err);
          }
        }
      });
  }

  return (
    <div>
      {!emailSent ? (
        <form onSubmit={sendingEmail}>
          <div className="section text-center">
            <h4 className="mb-2 pb-3">إعادة تعيين كلمة المرور</h4>
            <h6 className="mb-4 pb-3 text-white">
            لإعادة تعيين كلمة المرور ، يرجى ملء المعلومات التالية
            </h6>
            <div className="form-group">
              <input
                onChange={resetpasswordHandler}
                type="email"
                name="email"
                className="form-style"
                placeholder="البريد الالكتروني"
                id="logemail"
                autoComplete="off"
                required
              />
              <i className="input-icon uil uil-at"></i>
            </div>
            <div className="text-end text-light">{resetErrors.email}</div>
            {!loading ? (
              <button type="submit" className="btn mt-4">
                ارسال
              </button>
            ) : (
              <button className={`btn mt-4`} disabled>
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                ...ارسال
              </button>
            )}
          </div>
        </form>
      ) : (
        <form onSubmit={confirmPassword}>
          <div className="section text-center">
            <h4 className="mb-2 pb-3">إعادة تعيين كلمة المرور</h4>
            <h6 className="mb-4 pb-3 text-light">
            أدخل الرمز المرسل إلى بريدك الإلكتروني
              <br />
              ثم أعد تعيين كلمة المرور الخاصة بك
            </h6>
            <div className="form-group mb-2">
              <input
                onChange={resetpasswordHandler}
                type="text"
                name="pin_code"
                className="form-style"
                placeholder="رمز التحقق"
                autoComplete="off"
                required
              />
              <i className="input-icon uil uil-at"></i>
            </div>
            <div className="text-end text-light">
              {confirmErrors.pin_code}
            </div>

            <div className="form-group mb-2">
              <input
                onChange={resetpasswordHandler}
                type="password"
                name="password"
                className="form-style"
                placeholder="كلمة المرور"
                autoComplete="off"
                required
              />
              <i className="input-icon uil uil-at"></i>
            </div>
            <div className="text-end text-light">
              {confirmErrors.password}
            </div>

            <div className="form-group mb-2">
              <input
                onChange={resetpasswordHandler}
                type="password"
                name="password_confirmation"
                className="form-style"
                placeholder="تاكيد كلمة المرور"
                autoComplete="off"
                required
              />
              <i className="input-icon uil uil-at"></i>
            </div>
            <div className="text-start text-light">
              {confirmErrors.password_confirmation}
            </div>

            {!loading ? (
              <button type="submit" className="btn mt-4">
                ارسال
              </button>
            ) : (
              <button className={`btn mt-4`} disabled>
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                ...ارسال
              </button>
            )}
          </div>
        </form>
      )}
    </div>
  );
}
