import React from 'react';
import { Route , Routes } from 'react-router';
import Home from './Components/Home/Home';
import ProtectedRoute from './ProtectedRoute';
import AuthLayout from './layouts/authLayout/authLayout';
import { basename } from './config/config';

function App() {

  return (
    <React.Fragment>
      <Routes>  
        {/* ----- Admin Login ------*/}
        <Route path={`${basename}/*`} element={<AuthLayout />} />  
        {/* protected routes */}
        <Route element={<ProtectedRoute/>}>
          <Route path={`${basename}/dashboard/*`} element={<Home />} />
        </Route>
      </Routes>
    </React.Fragment>
  );
}

export default App;
