import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import './Login.css';
import { Link } from 'react-router-dom';
import { basename } from './../../config/config';
import {userLogin} from '../../api/authApi';

export default function Login() {
	let [errors, setErrors] = useState({ email: "", password: "" });
	let [loading, setLoading] = useState(false);
	let [Admin, setAdmin] = useState({ email: "", password: "" });
	let navigate = useNavigate();

	function adminHandler(e) {
		let mainAdmin = { ...Admin };
		mainAdmin[e.target.name] = e.target.value;
		setAdmin(mainAdmin);
		// to remove errors on change
		let err = { ...errors };
		err[e.target.name]='';
		setErrors(err);
	}

	function loginHandler(e) {
		e.preventDefault();
		setLoading(true);
		userLogin(Admin).then(res => {
				const Admin_Token = res.data.data.token;
				const Admin_Name = res.data.data.admin.name;
				localStorage.setItem('KB_Admin_Token', Admin_Token);
				localStorage.setItem('KB_Admin_Name', Admin_Name);
				setLoading(false);
				navigate(`${basename}/dashboard`);
				window.location.reload();
			})
			.catch(error => {
				setLoading(false)
                let err = { ...errors }
                for (const key in err) {
                  if (error?.response?.data?.data[key]) {
                    err = { ...err, [key]: error.response.data.data[key] };
                    setErrors(err);
                  }
                }
			})
	}

	return (
		<form onSubmit={loginHandler}>
			<div className="section text-center">
				<h4 className="mb-4 pb-3">تسجيل الدخول</h4>
				<div className="form-group">
					<input onChange={adminHandler} type="email" name="email" className="form-style" placeholder="البريد الالكتروني" id="logemail" autoComplete="off" required />
					<i className="input-icon uil uil-at"></i>
				</div>
				<div className="text-start text-light">{errors.email}</div>

				<div className="form-group mt-2">
					<input onChange={adminHandler} type="password" name="password" className="form-style" placeholder="كلمة المرور" id="logpass" autoComplete="off" required />
					<i className="input-icon uil uil-lock-alt"></i>
				</div>
				<div className="text-start text-light">{errors.password}</div>
				{!loading ?
					<button type='submit' className="btn mt-4">
						تاكيد
					</button>
					:
					<button className={`btn mt-4`} disabled>
						<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
						...تسجيل الدخول
					</button>
				}
				<div className="mb-0 mt-4 text-center forgetPass">
					<Link to={`${basename}/reset-password`} >نسيت كلمة المرور؟</Link>
				</div>
			</div>
		</form>

	)
}
