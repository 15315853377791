import React ,{useState,useEffect, useRef} from 'react';
import { Editor } from "react-draft-wysiwyg";
import { EditorState ,convertToRaw} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import style from './Projects.module.css';
import { useNavigate } from 'react-router';
import Swal  from 'sweetalert2';
import { basename } from '../../config/config';
import { addNewProject } from '../../api/projectApi';
import { getallTypes } from '../../api/type';
import { getallAreas } from '../../api/area';
import draftToHtml from "draftjs-to-html";

export default function CreateProject() {
    let navigate = useNavigate();
    let [newProject , setnewProject] = useState({});
    let [errors , setErrors] = useState({name:'',meta_description:'',meta_keywords:'',description:'',images:[],city:'',type:''});
    const [loading, setLoading] = useState(false);

    // let englishbodyRef = useRef();
    let arabicbodyRef = useRef();
    let imageRef = useRef();
    let metaKeywordsRef = useRef();

    /* for text editor */
    //eslint-disable-next-line
    let [convertedContent , setConvertedContent] = useState("");
    let [editorStateAr, setEditorStateAr] = useState(() => EditorState.createEmpty());
    
    /******* get all areas ********/
    let [areasOptions , setAreasOptions] = useState([]);
    /******* get all project types ********/
    let [typesOptions , setTypesOptions] = useState([]);

    useEffect(()=>{
        /** to get all areas **/
        getallAreas().then(res => {
            setAreasOptions(res.data.data);
        })
        /** to get all project types **/
        getallTypes().then(res => {
            setTypesOptions(res.data.data);
        })
    }, []);


    const convertArContentToHTML = () => {
        let currentContentAsHTML = draftToHtml(convertToRaw(editorStateAr.getCurrentContent()));
        setConvertedContent(currentContentAsHTML);
        return currentContentAsHTML;
    }

    const handleArEditorChange = (state) => {
        let classes = Array.from(arabicbodyRef.current.classList);
        if(classes.includes("shadow"))
        {
            arabicbodyRef.current.classList.remove("shadow");
        }
        let classes2 = Array.from(arabicbodyRef.current.nextSibling.classList);
        if(!classes2.includes("d-none"))
        {
            arabicbodyRef.current.nextSibling.classList.add("d-none");
        }
        setEditorStateAr(state);
        convertArContentToHTML();
    }

    /*************** set images ****************/
    let [propImgsFile ,setPropImgsFile] = useState([]);
    let [propImgsSrc ,setPropImgsSrc] = useState([]);
    function ImagesHandler(e){
        if(e.target.name === 'images' ){ 
            let imagesFiles = [...propImgsFile];
            let imagesSrc = [...propImgsSrc];
            let srcObj;
            for(let i=0; i<e.target.files.length; i++ ){
                srcObj = {file: e.target.files[i] , src:URL.createObjectURL(e.target.files[i])};
                imagesFiles.push(e.target.files[i]);
                imagesSrc.push(srcObj);
            }
            setPropImgsFile(imagesFiles);
            setPropImgsSrc(imagesSrc);
            let classes = Array.from(imageRef.current.nextSibling.classList);
            if(!classes.includes('d-none')){
                imageRef.current.nextSibling.classList.add("d-none");
            }
        }
    }

    function deleteImageHandler(file,i){
        let error = { ...errors };
        error[`images.${i}`] = ' ';
        setErrors(error);
        let imagesFiles = [...propImgsFile];
        let imagesSrc = [...propImgsSrc];
        let newFiles = imagesFiles.filter((m)=>( m !== file ));
        let newSrcs = imagesSrc.filter((m)=>( m.file !== file ));
        setPropImgsFile(newFiles);
        setPropImgsSrc(newSrcs);
    }
    /****************** set keywords **************************/ 
    let [keywords , setKeywords] = useState([]);
    function AddKeywordHandler(e) {
        let data = {...newProject};
        if(e.target.value.length > 20 ){
            if(e.keyCode !== 8){
                e.preventDefault();
            }
        }
        if(e.keyCode === 32 ){
            if(e.target.value.trim()){
            let propKeyword = [...keywords]
            propKeyword.push(e.target.value);
            setKeywords(propKeyword);
            e.target.value = "";
                if(keywords.length){
                    data['meta_keywords'] = keywords.join();
                    setnewProject(data);
                }
            }
        } 
    }

    /* to delete tag to tags array*/
    function removeKeywordHandler(i) {
        let propKeyword = [...keywords];
        let y = propKeyword.filter((key)=>propKeyword.indexOf(key) !== i );
        setKeywords(y);
    }

    /************************ new project handler *********************/
    function newProjectHandler(e){
        let mynewProject = {...newProject}
        mynewProject[e.target.name] = e.target.value;
        setnewProject(mynewProject);
    }

    /************** To submit creating new project ***************/
    let formData = new FormData();
    function submitNewProject(e){
        e.preventDefault();
        let data = {...newProject};
        let descriptionArabic = editorStateAr.getCurrentContent().getPlainText().trim(); 
        if(descriptionArabic === ""){
            window.scrollTo(arabicbodyRef.current.getBoundingClientRect().left + window.scrollX ,
            arabicbodyRef.current.getBoundingClientRect().top + window.scrollY - 150);
            arabicbodyRef.current.classList.add("shadow");
            arabicbodyRef.current.nextSibling.classList.remove("d-none");
        }


        /* requied image */
        if(propImgsFile.length === 0){
            window.scrollTo(imageRef.current.getBoundingClientRect().left + window.scrollX ,
            imageRef.current.getBoundingClientRect().top + window.scrollY - 150);
            imageRef.current.nextSibling.classList.remove("d-none");
        }else{
            data['images'] = propImgsFile;
        }
        
        if(propImgsFile.length && descriptionArabic !== ""){
            setLoading(true);
            let data = {...newProject ,'description': convertArContentToHTML() , 'images': [] , 'meta_keywords': keywords.join()}

            for (const key in data) {
                if(key === 'images'){
                    for(let i=0; i < propImgsFile.length; i++){
                        formData.append(`images[${i}]`, propImgsFile[i]);
                    }
                }else{
                    formData.append(key, data[key]);
                }           
            }
            /** to send request **/
            addNewProject(formData).then(res => {
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    text: "تمت اضافة المشروع بنجاح",
                    timer: 2000,
                    showConfirmButton: false
                });
                navigate(`${basename}/dashboard/all-projects`);
            })
            .catch(error => {
                setLoading(false);
                Swal.fire({
                    icon: 'error',
                    timer: 2500,
                    text: "عفوا حدث خطأ!",
                    showConfirmButton: false
                })
                let err = { ...errors }
                Object.entries(error.response.data.data).map(([key, value]) => {
                    err[key] = value;
                    setErrors(err);
                    return true;
                });
            })
        }
    }

    return (
        <>
        <section style={{"direction" : "rtl"}}>
            <div className='sectionWrapper'>
                <h4>انشاء مشروع جديد</h4>
                <form className='px-3 pt-4' onSubmit={submitNewProject}>
                    {/* project name */}
                    <div className="row g-5 mb-4">
                        <div className="col-12 col-lg-6">
                            <div className="form-outline ">
                                <label className="form-label labels" htmlFor="name"> اسم المشروع <span className='languages'> &#9733;</span></label>
                                <input onChange={newProjectHandler} type="text" name="name" id="name" className="form-control" required />
                            </div>
                        <div className='text-danger'>{errors.name} </div>
                        </div>
                    </div>
            
                    {/* meta keywords & meta description */}
                    <div className="row g-5 mb-4">
                        <div className="col-12 col-lg-6">
                            <div className="form-outline">
                                <label className="form-label labels" htmlFor="meta_keywords">الكلمات  المفتاحية  <span className='languages hintKeyWords'> (أدخل كلمة واحدة ثم اضغط مسافة)</span></label>
                                <div ref={metaKeywordsRef} className={`${style.LikeInput} d-flex align-items-center mw-100`}>
                                    <div>
                                        {keywords && keywords.map((t,index)=>(
                                            <div key={index} className={`${style.tagContainer}`}>
                                                {t}
                                                <svg onClick={() => removeKeywordHandler(index)} className={`removeTag`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.4" d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z" fill="#FF4B55"/>
                                                    <path d="M13.0594 12L15.3594 9.7C15.6494 9.41 15.6494 8.93 15.3594 8.64C15.0694 8.35 14.5894 8.35 14.2994 8.64L11.9994 10.94L9.69937 8.64C9.40937 8.35 8.92937 8.35 8.63938 8.64C8.34938 8.93 8.34938 9.41 8.63938 9.7L10.9394 12L8.63938 14.3C8.34938 14.59 8.34938 15.07 8.63938 15.36C8.78938 15.51 8.97937 15.58 9.16937 15.58C9.35937 15.58 9.54937 15.51 9.69937 15.36L11.9994 13.06L14.2994 15.36C14.4494 15.51 14.6394 15.58 14.8294 15.58C15.0194 15.58 15.2094 15.51 15.3594 15.36C15.6494 15.07 15.6494 14.59 15.3594 14.3L13.0594 12Z" fill="#FF4B55"/>
                                                </svg>
                                            </div>
                                        ))
                                        }
                                        <input onKeyDown={AddKeywordHandler} maxLength={20} type="text" name="meta_keywords" className={`${style.tagInput} border-0`} id="tags" />
                                    </div>
                                </div>
                                <div className='d-flex text-danger d-none'>
                                    يجب ادخال كلمة مفتاحية واحدة على الأقل                                
                                </div>
                                <div className='text-danger'>{errors.meta_keywords} </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="form-outline ">
                                <label className="form-label labels" htmlFor="meta_description">وصف بيانات التعريف</label>
                                <input type="text" onChange={newProjectHandler} id="meta_description" name='meta_description' className="form-control"/>
                            </div>
                        </div>
                    </div>
            
                    {/* types and areas sections */}
                    <div className="row g-5 mb-4">
                        <div className="col-12 col-lg-6">
                            <div className="form-outline ">
                                <label className="form-label labels" htmlFor="type"> نوع المشروع &#9733;</label>
                                <select onChange={newProjectHandler} defaultValue='' type="text" name='type' id="type" className="form-select" required>
                                    <option value='' disabled>اختر نوع المشروع</option>
                                    { typesOptions && typesOptions.map((type)=>(
                                        <option key={type.slug} value={type.slug}>
                                            {type.name}
                                        </option>
                                    )) 
                                    }
                                </select>
                            </div>
                            <div className='text-danger'>{errors.type} </div>

                        </div>
                        <div className="col">
                            <div className="form-outline ">
                                <label className="form-label labels" htmlFor="city">منطقة المشروع &#9733;</label>
                                <select defaultValue='' onChange={newProjectHandler} type="text" name='city' id="city" className="form-select" required>
                                    <option value='' disabled>اختر منطقة المشروع</option>
                                    { areasOptions && areasOptions.map((area)=>(
                                        <option key={area.slug} value={area.slug}>
                                            {area.name}
                                        </option>
                                    )) 
                                    }
                                </select>
                            </div>
                            <div className='text-danger'>{errors.city} </div>
                        </div>
                    </div>
            
                    {/* arabic text editor */} 
                    <div className="mb-4 d-flex">
                        <div className='w-100  mt-3'>
                            <label className="form-label labels" > وصف المشروع <span className='languages'> &#9733;</span></label>
                            <div ref={arabicbodyRef} className="w-100 textEditorWrapper">
                                <Editor
                                    editorState={editorStateAr}
                                    onEditorStateChange={handleArEditorChange}
                                    textAlignment = "right"
                                />
                            </div>
                            <div className='text-danger d-none'>هذا الحقل مطلوب !</div>
                        </div>                    
                    </div>

                    {/* image */}
                    <div>
                        <label className="form-label labels mb-3" htmlFor="upload">اختر صورا  &#9733;</label>
                        <div className={`mb-4 ${style.projectImgsContainer}`}>
                            {propImgsSrc &&
                                propImgsSrc.map((img , i)=>{
                                return(
                                    <div key={i} className={`${style.singleUploadedImages} position-relative mb-2`}>
                                        <div className={`${style.singleArticleImgContainer} position-relative h-100 w-100`} style={{backgroundImage: `url(${img.src})`}}>
                                            <div onClick={()=>deleteImageHandler(img.file,i)}  className={`position-absolute ${style.removeImageSvg}`} >
                                                <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM13.36 12.3C13.65 12.59 13.65 13.07 13.36 13.36C13.21 13.51 13.02 13.58 12.83 13.58C12.64 13.58 12.45 13.51 12.3 13.36L10 11.06L7.7 13.36C7.55 13.51 7.36 13.58 7.17 13.58C6.98 13.58 6.79 13.51 6.64 13.36C6.35 13.07 6.35 12.59 6.64 12.3L8.94 10L6.64 7.7C6.35 7.41 6.35 6.93 6.64 6.64C6.93 6.35 7.41 6.35 7.7 6.64L10 8.94L12.3 6.64C12.59 6.35 13.07 6.35 13.36 6.64C13.65 6.93 13.65 7.41 13.36 7.7L11.06 10L13.36 12.3Z" fill="#003260"/>
                                                </svg>
                                            </div>
                                        </div>   
                                        <div className='text-danger'> {errors[`images.${i}`]}</div>  
                                    </div>
                                    )
                                })
                            }

                            <div>
                                <div ref={imageRef} className={`${style.singleUploadedImages} position-relative p-1`}>
                                    <label htmlFor='first_image' className={`position-absolute d-flex flex-column justify-content-center align-items-center ${style.addPictureSvg} ${style.gallarySvgIcon}`}>
                                        <svg width="60" height="55" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M75.9177 66.8356C75.8087 66.6964 75.6695 66.5838 75.5105 66.5064C75.3516 66.4289 75.1771 66.3887 75.0003 66.3887C74.8235 66.3887 74.649 66.4289 74.4901 66.5064C74.3312 66.5838 74.1919 66.6964 74.083 66.8356L57.7745 87.4688C57.64 87.6405 57.5566 87.8465 57.5337 88.0634C57.5109 88.2803 57.5495 88.4992 57.6452 88.6951C57.7409 88.8911 57.8898 89.0561 58.0749 89.1715C58.26 89.2868 58.4738 89.3477 58.6918 89.3472H69.4525V124.643C69.4525 125.284 69.9767 125.808 70.6174 125.808H79.3541C79.9948 125.808 80.519 125.284 80.519 124.643V89.3618H91.3088C92.2844 89.3618 92.8232 88.2405 92.2262 87.4834L75.9177 66.8356Z" fill="#60626E" fillOpacity="0.75" />
                                        <path d="M118.596 53.3958C111.927 35.8059 94.9344 23.2979 75.0293 23.2979C55.1242 23.2979 38.1313 35.7913 31.4623 53.3812C18.9833 56.6575 9.76611 68.0298 9.76611 81.5425C9.76611 97.6326 22.7984 110.665 38.8739 110.665H44.7129C45.3536 110.665 45.8778 110.141 45.8778 109.5V100.763C45.8778 100.123 45.3536 99.5984 44.7129 99.5984H38.8739C33.9668 99.5984 29.3509 97.6472 25.9144 94.1088C22.4926 90.585 20.6724 85.8381 20.8326 80.9164C20.9637 77.0723 22.2742 73.4611 24.6476 70.4178C27.0793 67.3163 30.4867 65.0593 34.2726 64.0546L39.7912 62.613L41.8152 57.2836C43.0675 53.9637 44.8148 50.8621 47.0136 48.0518C49.1842 45.2664 51.7555 42.8178 54.6436 40.7858C60.6283 36.5776 67.6759 34.3498 75.0293 34.3498C82.3827 34.3498 89.4303 36.5776 95.4149 40.7858C98.3126 42.8244 100.875 45.2707 103.045 48.0518C105.244 50.8621 106.991 53.9782 108.243 57.2836L110.253 62.5984L115.757 64.0546C123.649 66.1805 129.168 73.3592 129.168 81.5425C129.168 86.3623 127.289 90.9054 123.882 94.3127C122.211 95.9934 120.223 97.326 118.034 98.2333C115.844 99.1406 113.496 99.6046 111.126 99.5984H105.287C104.647 99.5984 104.122 100.123 104.122 100.763V109.5C104.122 110.141 104.647 110.665 105.287 110.665H111.126C127.202 110.665 140.234 97.6326 140.234 81.5425C140.234 68.0443 131.046 56.6866 118.596 53.3958Z" fill="#60626E" fillOpacity="0.75" />
                                        </svg>   
                                        <input onChange={ImagesHandler} multiple name="images" type="file" className={`form-control ${style.addArticleInput} d-none`} id="first_image"/>
                                        <span>ارفع صورة </span>  
                                    </label>                           
                                </div>
                                <div className='text-danger mt-1 d-none'> قم برفع صورة واحدة على الأقل !</div> 
                            </div>
                        </div>
                    </div>
                    
                    <div className="mt-5">
                        <div  className="me-auto">
                            {!loading ?
                                <input className='createBtnContainer mb-2 py-1' type='submit' value='حفظ المشروع'/>
                            :
                                <button  className='createBtnContainer mb-2 py-1' disabled>
                                    جاري الحفظ
                                    <span className="spinner-border text-light spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                                </button>
                            }
                        </div>
                    </div>
                </form>
            </div>
        </section>
        </>
    )
}
