import { apiUrl } from '../config/config';
import { http } from './../config/http';

//get Blogs
export async function getAllBlogs(){
    return await http.get(`${apiUrl}/admin/blogs`);
}

//get blog by slug
export async function getblogDetails(slug){
    return await http.get(`${apiUrl}/admin/blogs/${slug}`);
}
//activate blogs
export async function activateBlogs(slug){
    return await http.post(`${apiUrl}/admin/blogs/${slug}/toggle-active`);
}
//delete blogs
export async function deleteBlogs(slug){
    return await http.post(`${apiUrl}/admin/blogs/${slug}/delete`);
}
//create blog
export async function addNewBlog(data){
    return await http.post(`${apiUrl}/admin/blogs/create`,data);
}
//edit blogs
export async function editBlog(slug,data){
    return await http.post(`${apiUrl}/admin/blogs/${slug}/update`,data);
}