import React from 'react'
import { useState, useRef } from 'react';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './admins.css';
import { useNavigate, useLocation, useParams } from 'react-router';
import { useEffect } from 'react';
import { editAdmin, getUser } from '../../api/authApi';
import { basename } from './../../config/config';
import Swal from 'sweetalert2';

export default function EditAdmin() {
    const [loading, setLoading] = useState(false);
    const [editedUser, seteditedUser] = useState({ name: ' ', username: " ", email: ' ', password: '', password_confirmation: '' });
    const [neweditedUser, setneweditedUser] = useState({});
    const [errors, setErrors] = useState({ name: '', username: '', email: '', password: '', password_confirmation: '' })
    const [showPassword, setShowpassword] = useState(false);
    const [showPasswordConfirm, setshowPasswordConfirm] = useState(false);
    const [passwordMatch, setpasswordMatch] = useState(true);
    let passwordRef = useRef();
    let cpasswordRef = useRef();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    
    let navigate = new useNavigate();
    const { slug } = useParams();

    useEffect(()=>{
        if(neweditedUser.password === neweditedUser.password_confirmation ){
            setpasswordMatch(false)
        }else{
            setpasswordMatch(true)
        }
    },[neweditedUser.password,neweditedUser.password_confirmation])

    useEffect(()=>{
        if(slug){
            getUser(slug).then((res)=>{
                let useCopy = { ...editedUser }
                useCopy = res.data.data
                seteditedUser(useCopy)
            })
        }
        //eslint-disable-next-line
    },[])

    function editAdminHandler(e) {
        let User = { ...editedUser };
        User[e.target.name] = e.target.value.trim();
        seteditedUser(User);
        let newEditUser = { ...neweditedUser };
        newEditUser[e.target.name] = e.target.value.trim();
        setneweditedUser(newEditUser);
        let error = { ...errors };
        if(e.target.name === 'password'){
            error[e.target.name] = [];
        }else{
            error[e.target.name] = ' ';
        }
        setErrors(error)
    }

    // password showing handler 
    function showPasswordHandler(x) {
        if (x === 'pass') {
            if (passwordRef.current.type === 'password') {
                passwordRef.current.type = 'text';
                setShowpassword(true)
            } else {
                passwordRef.current.type = 'password';
                setShowpassword(false)
            }
        } else if (x === 'cPass') {
            if (cpasswordRef.current.type === 'password') {
                cpasswordRef.current.type = 'text';
                setshowPasswordConfirm(true)
            } else {
                cpasswordRef.current.type = 'password';
                setshowPasswordConfirm(false)
            }
        }
    }

    function submitaddAdmin(e) {
        e.preventDefault();
        setLoading(true);
        let editedadmin={...neweditedUser}
        if(editedadmin.password === '' || editedadmin.password_confirmation === ''){
            delete editedadmin.password;
            delete editedadmin.password_confirmation
        }
        editAdmin(editedadmin,slug).then(()=>{
            setLoading(false)
            Swal.fire({
                icon: 'success',
                text: "تم تعديل المسئول بنجاح",
                timer: 2500,
                showConfirmButton: false
              }).then(() => {
                navigate(`${basename}/dashboard/admins`)
              })
            }).catch((error) => {
                setLoading(false)
                let err = { ...errors }
                for (const key in err) {
                  if (error.response.data.data[key]) {
                    err = { ...err, [key]: error.response.data.data[key] };
                    setErrors(err);
                  }
                }
        });
    }
    return (
        <section style={{ "direction": "rtl" }}>
            <div className='sectionWrapper'>
                <h4>تعديل مسئول </h4>
                <form  className='px-3 pt-4' onSubmit={submitaddAdmin}  autoComplete="off">
                    {/* project name */}
                    <div className="row g-5 mb-4">
                        <div className="col-lg-6 col-12">
                            <div className="form-outline ">
                                <label className="form-label labels" htmlFor="name">  الاسم <span className='languages'> &#9733;</span></label>
                                <input value={editedUser.name ?? ''} onChange={editAdminHandler} type="text" name="name" id="name" className="form-control" required/>
                            </div>
                        <div className='text-danger'>{errors.name}</div>
                        </div>
                    </div>
                    <div className="row g-5 mb-4">
                        <div className="col-lg-6 col-12">
                            <div className="form-outline ">
                                <label className="form-label labels" htmlFor="email">  البريد الالكتروني <span className='languages'> &#9733;</span></label>
                                <input value={editedUser.email ?? ''} onChange={editAdminHandler} type="text" name="email" id="email" className="form-control" required/>
                            </div>
                            <div className='text-danger'>{errors.email}</div>
                        </div>
                    </div>
                    <div className="row g-5 mb-4">
                        <div className="col-lg-6 col-12">
                            <div className="form-outline ">
                                <label className="form-label labels" htmlFor="password">  كلمة المرور<span className='languages'> &#9733;</span></label>
                                <div className='position-relative'>
                                    <input autoComplete="off" onChange={editAdminHandler} ref={passwordRef} type="password" name="password" id="password" className="form-control"  />
                                    {!showPassword ?
                                        <FontAwesomeIcon icon={faEye} className='eyeIcon' onClick={() => showPasswordHandler('pass')} /> :
                                        <FontAwesomeIcon icon={faEyeSlash} className='eyeIcon' onClick={() => showPasswordHandler('pass')} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-5 mb-4">
                        <div className="col-lg-6 col-12">
                            <div className="form-outline ">
                                <label className="form-label labels" htmlFor="password_confirmation">   تاكيد كلمة المرور <span className='languages'> &#9733;</span></label>
                                <div className='position-relative'>
                                    <input autoComplete="off"  onChange={editAdminHandler} ref={cpasswordRef} type="password" name="password_confirmation" id="password_confirmation" className="form-control"  />
                                    {!showPasswordConfirm ?
                                        <FontAwesomeIcon icon={faEye} className='eyeIcon' onClick={() => showPasswordHandler('cPass')} /> :
                                        <FontAwesomeIcon icon={faEyeSlash} className='eyeIcon' onClick={() => showPasswordHandler('cPass')} />
                                    }
                                </div>
                            </div>
                            {(!passwordMatch && (editedUser?.password)  && (editedUser?.password_confirmation)) ?  
                                <div className='text-success'>كلمة المرور متطابقة</div>
                            :(passwordMatch && editedUser?.password !== '' && editedUser?.password_confirmation !== '' ) && <div className='text-danger'>كلمة المرور  غير متطابقة</div>}
                            
                            {errors && errors.password.length ? errors.password.map((pass)=>(
                            <div className='text-danger'>{pass}</div>
                             )):""} 
                        </div>
                    </div>

                    <div className="mt-5">
                        <div  className="me-auto">
                            {!loading ?
                                <input className='createBtnContainer mb-2 py-1' type='submit' value='تعديل المسئول' />
                                :
                                <button className='createBtnContainer mb-2 py-1' disabled>
                                    جاري الحفظ
                                    <span className="spinner-border text-light spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                </button>
                            }
                        </div>
                    </div>
                </form>
            </div>
        </section>
    )
}
