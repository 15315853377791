import React from 'react';
import './Aside.css';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCity, faClipboardList , faCog ,faUser, faBookOpen,faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { BiBuildingHouse } from 'react-icons/bi';
import { IoMdPhotos } from 'react-icons/io';
import { BiCategory } from 'react-icons/bi';
import { basename } from './../../config/config';

export default function Aside() {
    return(
    <div className='asideContainer'>
        <div className='offcanvas offcanvas-start' tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel"></div>
            <div className='AppNameContainer d-flex align-items-center justify-content-end'>
                <NavLink className='d-flex align-items-center linkDashboard' to=''>
                    <div className='mx-auto my-auto'>
                        <p className='dashboardParagraph'>لوحة التحكم</p>
                    </div>
                </NavLink>
            </div>

        <div className='listItemsContainer'>
            <ul className='listItems'>
                <li>
                    <NavLink to={`${basename}/dashboard/all-projects`} className={(navData) => navData.isActive ? 'activeLink' : ""}>
                        <BiBuildingHouse />
                        <div>
                            المشاريع
                        </div>
                    </NavLink>
                </li>

                <li>
                    <NavLink to={`${basename}/dashboard/all-blogs`} className={(navData) => navData.isActive ? 'activeLink' : ""}>
                        <FontAwesomeIcon icon={faClipboardList} />
                        <div>
                            الأخبار 
                        </div>
                    </NavLink>
                </li>

                <li>
                    <NavLink to={`${basename}/dashboard/project-types`} className={(navData) => navData.isActive ? 'activeLink' : ""}>
                        <BiCategory/>
                        <div>
                            أنواع المشاريع 
                        </div>
                    </NavLink>
                </li>

                <li>
                    <NavLink to={`${basename}/dashboard/areas`} className={(navData) => navData.isActive ? 'activeLink' : ""}>
                        <FontAwesomeIcon icon={faCity} />
                        <div>
                            المناطق 
                        </div>
                    </NavLink>
                </li>

                <li>
                    <NavLink to={`${basename}/dashboard/gallery`} className={(navData) => navData.isActive ? 'activeLink' : ""}>
                        <IoMdPhotos/>
                        <div>
                            معرض الصور 
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`${basename}/dashboard/admins`} className={(navData) => navData.isActive ? 'activeLink' : ""}>
                        <FontAwesomeIcon icon={faUser} />
                        <div>
                            المسئولين
                        </div>
                    </NavLink>
                </li>

                <li>
                    <NavLink to={`${basename}/dashboard/contact-us`} className={(navData) => navData.isActive ? 'activeLink' : ""}>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <div>
                            الرسائل 
                        </div>
                    </NavLink>
                </li>
                <hr className='asideSplitter'></hr>
                <li>
                    <NavLink to={`${basename}/dashboard/pages`} data-bs-toggle="collapse" data-bs-target="#pagesCollapse" className={(navData) => navData.isActive ? 'activeLink' : ""}>
                        <FontAwesomeIcon icon={faBookOpen} />
                        <div>
                            الصفحات 
                        </div>
                    </NavLink>
                    <ul className="nestedLinks collapse asideDropdown" id='pagesCollapse'>
                        <li><NavLink to={`${basename}/dashboard/pages/about-us`} >من نحن </NavLink></li>
                        <li><NavLink to={`${basename}/dashboard/pages/headers`}> عناوين الصفحات</NavLink></li>

                    </ul>
                </li>
                {/* website settings */}
                <li>
                    <NavLink to={`${basename}/dashboard/website-settings`} data-bs-toggle="collapse" data-bs-target="#websiteSettingsCollapse" className={(navData) => navData.isActive ? 'activeLink' : ""}>
                        <FontAwesomeIcon icon={faCog} />
                        <div>
                            ضبط الموقع
                        </div>
                    </NavLink>
                    <ul className="nestedLinks collapse asideDropdown" id='websiteSettingsCollapse'>
                        <li><NavLink to={`${basename}/dashboard/website-settings/general-settings`}>  اعدادات الموقع</NavLink></li>
                        <li><NavLink to={`${basename}/dashboard/website-settings/slider-images`} >  خلفيات الصفحة الرئيسية</NavLink></li>
                        <li><NavLink to={`${basename}/dashboard/website-settings/goals`} >أهدافنا</NavLink></li>
                        <li><NavLink to={`${basename}/dashboard/website-settings/socials`} >وسائل التواصل الاجتماعي</NavLink></li>
                        {/* <li><NavLink to={`${basename}/dashboard/website-settings/partners`}>  شركاء النجاح</NavLink></li> */}
                        {/* <li><NavLink to={`${basename}/dashboard/website-settings/statistics`}>احصائيات </NavLink></li> */}
                    </ul>
                </li>
            </ul>
        </div>
    </div>
    )
}
