import { apiUrl } from '../config/config';
import { http } from './../config/http';

//get Gallery
export async function getAllGallery(){
    return await http.get(`${apiUrl}/admin/photos-gallery`);
}
//get Gallery
export async function getPhotoDetails(slug){
    return await http.get(`${apiUrl}/admin/photos-gallery/${slug}`);
}
//add Gallery photo
export async function addGallery(data){
    return await http.post(`${apiUrl}/admin/photos-gallery/create`,data);
}
//activate photo gallery
export async function activateGallery(slug){
    return await http.post(`${apiUrl}/admin/photos-gallery/${slug}/toggle-active`);
}
//delete photos-gallery
export async function deleteGallery(slug){
    return await http.post(`${apiUrl}/admin/photos-gallery/${slug}/delete`);
}

//edit photos-gallery
export async function editGallery(slug,data){
    return await http.post(`${apiUrl}/admin/photos-gallery/${slug}/update`,data);
}