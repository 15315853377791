import { apiUrl } from '../config/config';
import { http } from './../config/http';

//get generalSettings
export async function getAllGeneralSettings(){
    return await http.get(`${apiUrl}/admin/settings/general`);
}
//edit generalSettings
export async function updateGeneralSettings(data){
    return await http.post(`${apiUrl}/admin/settings/general`,data);
}