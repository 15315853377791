import { apiUrl } from '../config/config';
import { http } from './../config/http';

//get about us data
export async function getAboutUs(){
    return await http.get(`${apiUrl}/admin/settings/about-us`);
}
//update about us data
export async function updateAboutUs(data){
    return await http.post(`${apiUrl}/admin/settings/about-us`,data);
}

//get Advantages
export async function getAllAdvantages(){
    return await http.get(`${apiUrl}/admin/advantages`);
}
//get Advantage details
export async function getAdvantageDetail(slug){
    return await http.get(`${apiUrl}/admin/advantages/${slug}`);
}
//activate Advantages
export async function activateAdvantages(slug){
    return await http.post(`${apiUrl}/admin/advantages/${slug}/toggle-active`);
}
//delete Advantages
export async function deleteAdvantages(slug){
    return await http.post(`${apiUrl}/admin/advantages/${slug}/delete`);
}
//create Advantages
export async function addNewAdvantage(data){
    return await http.post(`${apiUrl}/admin/advantages/create`,data);
}
//edit Advantages
export async function editAdvantage(slug,data){
    return await http.post(`${apiUrl}/admin/advantages/${slug}/update`,data);
}
//get headers 
export async function getallHeaders(){
    return await http.get(`${apiUrl}/admin/headers`);
}
//update headers 
export async function updateHeaders(data){
    return await http.post(`${apiUrl}/admin/headers/update`,data);
}