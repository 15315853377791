import React from 'react';
// import axios from 'axios';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOut, faBars } from '@fortawesome/free-solid-svg-icons'
import Tooltip from "@material-ui/core/Tooltip";
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { basename } from '../../config/config';
import { faCity, faClipboardList, faCog, faUser, faBookOpen, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { BiBuildingHouse, BiCategory } from 'react-icons/bi';
import { IoMdPhotos } from 'react-icons/io';
import { useState } from 'react';


export default function Header() {
    const [collapse, setCollapse] = useState(false);
    const toggleCollapse = () => {
      setCollapse(!collapse);
    };
    const handleNavLinkClick = () => {
      // if (collapse) {
        toggleCollapse();
        console.log(collapse)
      // }
    };
  
    let AdminName = localStorage.getItem('KB_Admin_Name');
    const navigate = useNavigate();


    function logoutHandler() {
        localStorage.removeItem("KB_Admin_Token");
        localStorage.removeItem("KB_Admin_Name");
        navigate(`/${basename}`);
    }

    return (
        <div className='headerContainer shadow'>
            <div className=' h-100'>
                <div className='d-flex align-items-center h-100'>
                    <Tooltip title="تسجيل الخروج">
                        <div className='logOutIcon' onClick={logoutHandler}>
                            <FontAwesomeIcon icon={faSignOut} />
                        </div>
                    </Tooltip>
                    <Tooltip title='تفاصيل الحساب'>
                        <div className='profilePicture mx-3'>
                            <Link to={`${basename}/dashboard/profile`}>
                                <div>
                                    {AdminName?.charAt(0)}
                                </div>
                            </Link>
                        </div>
                    </Tooltip>
                </div>
                <div className={`${collapse && 'collapsedNB'} collapse navbar-collapse bg-white `}  id="navbarNav">
                    <ul className="navbar-nav p-0">
                        <div className='AppNameContainer d-flex align-items-center justify-content-end'>
                            <NavLink onClick={handleNavLinkClick} className='d-flex align-items-center w-100 linkDashboard' to=''>
                                <div className='mx-auto my-auto'>
                                    <p className='dashboardParagraph'>لوحة التحكم</p>
                                </div>
                            </NavLink>
                        </div>

                        <div className='listItemsContainer'>
                            <ul className='listItems  px-lg-5 px-2'>
                                <li>
                                    <NavLink onClick={handleNavLinkClick} to={`${basename}/dashboard/all-projects`} className={(navData) => navData.isActive ? 'activeLink' : "text-dark"}>
                                        <BiBuildingHouse />
                                        <div>
                                            المشاريع
                                        </div>
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink onClick={handleNavLinkClick} to={`${basename}/dashboard/all-blogs`} className={(navData) => navData.isActive ? 'activeLink' : "text-dark"}>
                                        <FontAwesomeIcon icon={faClipboardList} />
                                        <div>
                                            الأخبار
                                        </div>
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink onClick={handleNavLinkClick} to={`${basename}/dashboard/project-types`} className={(navData) => navData.isActive ? 'activeLink' : "text-dark"}>
                                        <BiCategory />
                                        <div>
                                            أنواع المشاريع
                                        </div>
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink onClick={handleNavLinkClick} to={`${basename}/dashboard/areas`} className={(navData) => navData.isActive ? 'activeLink' : "text-dark"}>
                                        <FontAwesomeIcon icon={faCity} />
                                        <div>
                                            المناطق
                                        </div>
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink onClick={handleNavLinkClick} to={`${basename}/dashboard/gallery`} className={(navData) => navData.isActive ? 'activeLink' : "text-dark"}>
                                        <IoMdPhotos />
                                        <div>
                                            معرض الصور
                                        </div>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={handleNavLinkClick} to={`${basename}/dashboard/admins`} className={(navData) => navData.isActive ? 'activeLink' : "text-dark"}>
                                        <FontAwesomeIcon icon={faUser} />
                                        <div>
                                            المسئولين
                                        </div>
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink onClick={handleNavLinkClick} to={`${basename}/dashboard/contact-us`} className={(navData) => navData.isActive ? 'activeLink' : "text-dark"}>
                                        <FontAwesomeIcon icon={faEnvelope} />
                                        <div>
                                            الرسائل
                                        </div>
                                    </NavLink>
                                </li>
                                <hr className='asideSplitter'></hr>
                                <li>
                                    <NavLink to={`${basename}/dashboard/pages`} data-bs-toggle="collapse" data-bs-target="#pagesCollapse" className={(navData) => navData.isActive ? 'activeLink' : "text-dark"}>
                                        <FontAwesomeIcon icon={faBookOpen} />
                                        <div>
                                            الصفحات
                                        </div>
                                    </NavLink>
                                    <ul className="nestedLinks collapse asideDropdown" id='pagesCollapse'>
                                        <li><NavLink onClick={handleNavLinkClick} className={"text-dark"} to={`${basename}/dashboard/pages/about-us`} >من نحن </NavLink></li>
                                        <li><NavLink onClick={handleNavLinkClick} className={"text-dark"} to={`${basename}/dashboard/pages/headers`}> عناوين الصفحات</NavLink></li>

                                    </ul>
                                </li>
                                <li>
                                    <NavLink to={`${basename}/dashboard/website-settings`} data-bs-toggle="collapse" data-bs-target="#websiteSettingsCollapse" className={(navData) => navData.isActive ? 'activeLink' : "text-dark"}>
                                        <FontAwesomeIcon icon={faCog} />
                                        <div>
                                            ضبط الموقع
                                        </div>
                                    </NavLink>
                                    <ul className="nestedLinks collapse asideDropdown" id='websiteSettingsCollapse'>
                                        <li><NavLink onClick={handleNavLinkClick} className={"text-dark"} to={`${basename}/dashboard/website-settings/general-settings`}>  اعدادات الموقع</NavLink></li>
                                        <li><NavLink onClick={handleNavLinkClick} className={"text-dark"} to={`${basename}/dashboard/website-settings/slider-images`} >  خلفيات الصفحة الرئيسية</NavLink></li>
                                        <li><NavLink onClick={handleNavLinkClick} className={"text-dark"} to={`${basename}/dashboard/website-settings/goals`} >أهدافنا</NavLink></li>
                                        <li><NavLink onClick={handleNavLinkClick} className={"text-dark"} to={`${basename}/dashboard/website-settings/socials`} >وسائل التواصل الاجتماعي</NavLink></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </ul>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg bg-body-tertiary border-0">
                <div className="container-fluid border-0">
                    <button onClick={handleNavLinkClick} className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                </div>
            </nav>
        </div>
    )
}
