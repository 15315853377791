import { apiUrl } from '../config/config';
import { http } from './../config/http';

//get our-partners
export async function getAllPartners(){
    return await http.get(`${apiUrl}/admin/our-partners`);
}
//get Partner by slug
export async function getPartnerDetails(slug){
    return await http.get(`${apiUrl}/admin/our-partners/${slug}`);
}
//activate our-partners
export async function activatePartner(slug){
    return await http.post(`${apiUrl}/admin/our-partners/${slug}/toggle-active`);
}
//delete our-partners
export async function removePartner(slug){
    return await http.post(`${apiUrl}/admin/our-partners/${slug}/delete`);
}
//add our-partners
export async function addNewPartner(data){
    return await http.post(`${apiUrl}/admin/our-partners/create`,data);
}
//edit our-partners
export async function editPartnerDetails(slug,data){
    return await http.post(`${apiUrl}/admin/our-partners/${slug}/update`,data);
}