import { apiUrl } from '../config/config';
import { http } from './../config/http';

export async function getallAreas() {
    return await http.get(`${apiUrl}/admin/cities`);
}
export async function getArea(id) {
    return await http.get(`${apiUrl}/admin/cities/${id}`);
}
export async function addArea(data){
    return await http.post(`${apiUrl}/admin/cities/create`,data);
}
export async function editArea(slug,data){
    return await http.post(`${apiUrl}/admin/cities/${slug}/update`,data);
}
export async function deleteArea(slug){
    return await http.post(`${apiUrl}/admin/cities/${slug}/delete`);
}