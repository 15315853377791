import React,{ useEffect,useState }  from 'react'
// import { basename, apiHost } from './../../config/config';
// import { Link } from 'react-router-dom';
import style from "../Types/Types.module.css";
// import { activateSocial, getcontacts, removeSocial } from '../../api/socialsApi';
// import { useState } from 'react';
// import moment from 'moment';
// import './social.css';
import Tooltip from "@material-ui/core/Tooltip";
// import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { getAllcontacts, getcontactDetails, readContact } from '../../api/contactApi';
import moment from 'moment';
import './contactus.css';

export default function ContactUs() {
    const [contacts,setContacts]=useState([]);
    const [contactDetails,setcontactDetails]=useState({});
    const [contactSlug,setcontactSlug]=useState('');
    function getContacts(){
        getAllcontacts().then((res)=>{
            let socials=res.data.data;
            setContacts(socials);
        })
    }
    useEffect(()=>{
        getContacts()
        if(contactSlug !== ''){
            getcontactDetails(contactSlug).then((res)=>{
                let contact=res.data.data;
                setcontactDetails(contact);
            });
        }
    },[contactSlug])

    function viewMessage(slug){
        setcontactSlug(slug);
        readContact(slug).then(()=>{
            getContacts();
        })
    }
   
  return (
    <section style={{ "direction": "rtl" }}>
        <div className='sectionWrapper'>
        {/** displaying data in table **/}
        <h3 className='contactusHeader'> قائمة الرسائل</h3>
        {contacts && contacts.length > 0 ?
              <div className='table-responsive'>

        <table className={`${style.types_table} table table-striped`} dir='rtl'>
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">الاسم  </th>
                    <th scope="col">البريد الالكتروني  </th>
                    <th scope="col">التاريخ</th>
                    {/* <th scope="col">تعديل</th>
                    <th scope="col">حذف</th> */}
                    <th scope="col">قراءة</th>
                </tr>
            </thead>
            <tbody className={`${style.noBorder}`}>
                {contacts && contacts.map((social, index) => (
                    <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{social.name}</td>
                        <td>{social.email}</td>
                        <td>{moment(social.created_at).format('DD/MM/YYYY')}</td>
                        <td>
                        { !social.read ?
                                <Tooltip title="قرائة الرسالة" data-bs-toggle="modal" data-bs-target="#messageDetails">
                                    <div onClick={()=>viewMessage(social.slug)} className={`${style.brandsIcon} toggleIcon`} >
                                        <FontAwesomeIcon icon={faEnvelope}/>
                                    </div>
                                </Tooltip>
                                :
                                <Tooltip title="قرائة الرسالة">
                                    <div onClick={()=>viewMessage(social.slug)}  data-bs-toggle="modal" data-bs-target="#messageDetails" className={`${style.brandsIcon} toggleIcon`} >
                                        <FontAwesomeIcon icon={faEnvelopeOpen}/>
                                    </div>
                                 </Tooltip>
                            }
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
</div>
         :<div className='noDataClass d-flex justify-content-center align-items-center'>لا توجد بيانات</div>
        }
    </div>
    <div className="modal fade contactModal" tabIndex="-1" id='messageDetails'  data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">تفاصيل الرسالة </h5>
                <button onClick={()=>setcontactDetails({})} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <h6> الاسم : <span className='msgDetails'>{contactDetails?.name ?? ''}</span></h6>
                <h6> رقم الهاتف : <span className='msgDetails'>{contactDetails?.phone ?? ''}</span></h6>
                <h6>الرسالة : 
                <span className='msgDetails'> {contactDetails?.message ?? ''}</span>
                </h6>
            </div>
            </div>
        </div>
    </div>
</section>
  )
}
