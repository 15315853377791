import React, { useRef, useState ,useEffect} from 'react';
import Swal from 'sweetalert2';
import { useNavigate, useLocation } from 'react-router';
import style from "../../Gallery/Gallery.module.css";
import { basename } from '../../../config/config';
import { addNewStatistic } from '../../../api/statisticsApi';

export default function AddStatistic() {
    let [statisticData, setstatisticData] = useState({ image: '', name: '' ,value: ''});
    let [errors, setErrors] = useState({  });
    let [imgFile, setimgFile] = useState('');
    let imgRef = useRef();
    let [loading, setLoading] = useState(false);
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    let navigate = new useNavigate();

 
    function imageDataHandler(e) {
        let mystatisticData = { ...statisticData }
        if (e.target.name === 'image') {
            mystatisticData[e.target.name] = e.target.files[0];
            setimgFile(e.target.files[0]);

        } else {
            mystatisticData[e.target.name] = e.target.value.trim();
        }
        setstatisticData(mystatisticData);

        let error = { ...errors };
        error[e.target.name]= '';
        setErrors(error);
    }

    function deleteImageHandler() {
        setimgFile('')
        let mystatisticData = { ...statisticData }
        mystatisticData.image = ''
        setstatisticData(mystatisticData);
    }
    function addPhotoHandler(e) {
        e.preventDefault();
        let formData = new FormData();
        let mystatisticData = { ...statisticData }
        for (const key in mystatisticData) {
            formData.append(key, mystatisticData[key]);
        }
        
        setLoading(true);
        if (imgFile === '') {
            window.scrollTo(imgRef.current.getBoundingClientRect().left + window.scrollX,
                imgRef.current.getBoundingClientRect().top + window.scrollY - 150);
            imgRef.current.nextSibling.classList.remove("d-none");
            setLoading(false)
        } else {
            addNewStatistic(formData).then(res => {
                Swal.fire({
                    icon: 'success',
                    text: "تم الاضافة بنجاح",
                    timer: 2000,
                    showConfirmButton: false,
                }).then(() => {
                    navigate(`${basename}/dashboard/website-settings/statistics`)
                  })
                setLoading(false)
            }).catch(error => {
                setLoading(false)
                Swal.fire({
                    icon: 'error',
                    text:error.response.data.message,
                    confirmButtonText:'تم'
                })
                let err = { ...errors }
                Object.entries(error.response.data.data).map(([key, value]) => {
                    err[key] = value;
                    setErrors(err);
                    return  true;
                });
            })
        }

    }

    return (
        <div>
            <form className={`sectionWrapper d-flex flex-column`}  onSubmit={addPhotoHandler}>
                {/* <div > */}
                <h4  className='mb-3'>اضافة احصائية </h4>
                <div className='col-8'>
                    <div className="form-outline mb-4">
                        <label className="form-label labels" htmlFor="name">العنوان  </label>
                        <input onChange={imageDataHandler} type="text" name='name' id="name" className="form-control" required/>
                    <div className='text-danger'>{errors.name} </div>
                    </div>
                </div>
                <div className='col-8'>
                    <div className="form-outline mb-4">
                        <label className="form-label labels" htmlFor="value">الرقم </label>
                        <input type='number' onChange={imageDataHandler} id="value" name='value' className="form-control"  required/>
                        <div className='text-danger'>{errors.value} </div>
                    </div>
                </div>

                <div className='col-2'>
                    <div>
                        <label className='labels mb-2'>ارفع الصورة</label>
                        {imgFile ?
                            <div className={`${style.singleUploadedImages} position-relative`}>
                                <img src={URL.createObjectURL(imgFile)} className={`${style.singleUploadedImages} smallImg`} alt='gallery img' />
                                <div onClick={() => deleteImageHandler()} className={`position-absolute ${style.removeImageSvg}`} >
                                    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM13.36 12.3C13.65 12.59 13.65 13.07 13.36 13.36C13.21 13.51 13.02 13.58 12.83 13.58C12.64 13.58 12.45 13.51 12.3 13.36L10 11.06L7.7 13.36C7.55 13.51 7.36 13.58 7.17 13.58C6.98 13.58 6.79 13.51 6.64 13.36C6.35 13.07 6.35 12.59 6.64 12.3L8.94 10L6.64 7.7C6.35 7.41 6.35 6.93 6.64 6.64C6.93 6.35 7.41 6.35 7.7 6.64L10 8.94L12.3 6.64C12.59 6.35 13.07 6.35 13.36 6.64C13.65 6.93 13.65 7.41 13.36 7.7L11.06 10L13.36 12.3Z" fill="#003260" />
                                    </svg>
                                </div>
                            </div> :
                            <div>
                                <div ref={imgRef} className={`${style.singleUploadedImages} position-relative`}>
                                    <label htmlFor='imagefile' className={`position-absolute ${style.gallarySvgIcon}`}>
                                        <svg width="70" height="70" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M75.9177 66.8356C75.8087 66.6964 75.6695 66.5838 75.5105 66.5064C75.3516 66.4289 75.1771 66.3887 75.0003 66.3887C74.8235 66.3887 74.649 66.4289 74.4901 66.5064C74.3312 66.5838 74.1919 66.6964 74.083 66.8356L57.7745 87.4688C57.64 87.6405 57.5566 87.8465 57.5337 88.0634C57.5109 88.2803 57.5495 88.4992 57.6452 88.6951C57.7409 88.8911 57.8898 89.0561 58.0749 89.1715C58.26 89.2868 58.4738 89.3477 58.6918 89.3472H69.4525V124.643C69.4525 125.284 69.9767 125.808 70.6174 125.808H79.3541C79.9948 125.808 80.519 125.284 80.519 124.643V89.3618H91.3088C92.2844 89.3618 92.8232 88.2405 92.2262 87.4834L75.9177 66.8356Z" fill="#60626E" fillOpacity="0.75" />
                                            <path d="M118.596 53.3958C111.927 35.8059 94.9344 23.2979 75.0293 23.2979C55.1242 23.2979 38.1313 35.7913 31.4623 53.3812C18.9833 56.6575 9.76611 68.0298 9.76611 81.5425C9.76611 97.6326 22.7984 110.665 38.8739 110.665H44.7129C45.3536 110.665 45.8778 110.141 45.8778 109.5V100.763C45.8778 100.123 45.3536 99.5984 44.7129 99.5984H38.8739C33.9668 99.5984 29.3509 97.6472 25.9144 94.1088C22.4926 90.585 20.6724 85.8381 20.8326 80.9164C20.9637 77.0723 22.2742 73.4611 24.6476 70.4178C27.0793 67.3163 30.4867 65.0593 34.2726 64.0546L39.7912 62.613L41.8152 57.2836C43.0675 53.9637 44.8148 50.8621 47.0136 48.0518C49.1842 45.2664 51.7555 42.8178 54.6436 40.7858C60.6283 36.5776 67.6759 34.3498 75.0293 34.3498C82.3827 34.3498 89.4303 36.5776 95.4149 40.7858C98.3126 42.8244 100.875 45.2707 103.045 48.0518C105.244 50.8621 106.991 53.9782 108.243 57.2836L110.253 62.5984L115.757 64.0546C123.649 66.1805 129.168 73.3592 129.168 81.5425C129.168 86.3623 127.289 90.9054 123.882 94.3127C122.211 95.9934 120.223 97.326 118.034 98.2333C115.844 99.1406 113.496 99.6046 111.126 99.5984H105.287C104.647 99.5984 104.122 100.123 104.122 100.763V109.5C104.122 110.141 104.647 110.665 105.287 110.665H111.126C127.202 110.665 140.234 97.6326 140.234 81.5425C140.234 68.0443 131.046 56.6866 118.596 53.3958Z" fill="#60626E" fillOpacity="0.75" />
                                        </svg>
                                        <input onChange={imageDataHandler} name="image" type="file" className={`form-control d-none`} id="imagefile" />
                                    </label>
                                </div>
                                <div className='text-danger d-none'>يجب ادخال صورة</div>
                            </div>
                        }
                        <div className='text-danger'>{errors.image} </div>
                    </div>
                </div>
                {!loading ?
                    <button type='submit' className='createBtnContainer mt-4'>حفظ</button>
                    :
                    <button className='createBtnContainer mt-4' disabled>
                        جاري الحفظ
                        <span className="spinner-border text-light spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    </button>
                }
            </form>
            </div>
    )
}
