import React ,{useEffect , useState} from 'react';
import style from './Projects.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';
import Swal  from 'sweetalert2';
import { Link } from 'react-router-dom';
import { BiCategory } from 'react-icons/bi';
import { GoLocation } from 'react-icons/go';
import { activateProjects, getAllProjects, removeProject } from '../../api/projectApi';
import { apiHost, basename } from '../../config/config';


export default function Projects() {



    let [projects ,setprojects] = useState([]);
    let [oneProject , setOneProject] = useState({});

    function getProjects() {
        getAllProjects().then(res => {
            setprojects(res.data.data);
        })
    }
    
    useEffect(()=>{
        getProjects();
    },[]);

    /* active and inactive blog */
    function toggleActivate(slug) {
        activateProjects(slug).then(res => {
            Swal.fire({
                icon: 'success',
                title: res.data.data.message,
                showConfirmButton: true,
                confirmButtonColor: '#F7A116',
            })
            getProjects();
        })
        .catch(() => {
            Swal.fire({
                icon: 'error',
                text: 'عفوا لقد حدث خطأ !'
            });
        })
    }

    /******************  delete project  ************************/
    function getOneProject(p){
        setOneProject(p);
    }

    function deleteProject(){
        let slug = oneProject.slug;
        // to send request
        removeProject(slug).then(res => {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'تم الحذف بنجاح',
                showConfirmButton: false,
                timer: 2000
            });
            getProjects();
        }).catch(err => {
            Swal.fire({
                icon: 'error',
                text: err.response.data.message,
                confirmButtonText:'تم'
            }).then(()=>{
                getProjects();
            })
        });
    }


    return (
        <section style={{"direction" : "rtl"}}>
            <div className='sectionWrapper'>
                <div className='createBtnContainer'>
                    <Link to={`${basename}/dashboard/all-projects/create-project`}>
                        انشاء مشروع
                    </Link>
                </div>
                
                {(projects && projects.length)?
                    <div className="row row-cols-1 row-cols-lg-auto g-3
                    ">
                        {(projects && projects.length) ? projects.map((singleProject)=>(
                            <div key={singleProject.slug} className="col">
                                <div>
                                    <div className={`${style.project_grid} h-100`}>
                                        <div className={`${style.project_img}`}>                                    
                                            <img src={`${apiHost}/${(singleProject.images && singleProject.images.length) ? singleProject.images[0].file : ""}`} title="" alt="project" />
                                            <div className={`${style.projectLayer}`}> 
                                            </div>                                    
                                            <div className={`${style.iconsContainer}`}>
                                                <div className='d-flex'>
                                                    <Tooltip title="تعديل المشروع ">
                                                        <Link to={`${basename}/dashboard/all-projects/edit-project/${singleProject.slug}`} className='blogIcon editIconBlog mx-1 d-flex align-items-center justify-content-center' style={{"cursor" : "pointer"}}>
                                                            <FontAwesomeIcon icon={faEdit}/>
                                                        </Link>
                                                    </Tooltip>
                                    
                                                    <Tooltip title="حذف المشروع ">
                                                        <div onClick={()=> getOneProject(singleProject)} className='blogIcon mx-1 d-flex align-items-center justify-content-center' data-bs-toggle="modal" data-bs-target={`#deleteProject`} style={{"cursor" : "pointer"}}>
                                                            <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M21.0697 5.23C19.4597 5.07 17.8497 4.95 16.2297 4.86V4.85L16.0097 3.55C15.8597 2.63 15.6397 1.25 13.2997 1.25H10.6797C8.34967 1.25 8.12967 2.57 7.96967 3.54L7.75967 4.82C6.82967 4.88 5.89967 4.94 4.96967 5.03L2.92967 5.23C2.50967 5.27 2.20967 5.64 2.24967 6.05C2.28967 6.46 2.64967 6.76 3.06967 6.72L5.10967 6.52C10.3497 6 15.6297 6.2 20.9297 6.73C20.9597 6.73 20.9797 6.73 21.0097 6.73C21.3897 6.73 21.7197 6.44 21.7597 6.05C21.7897 5.64 21.4897 5.27 21.0697 5.23Z" fill="#ffffffbe"/>
                                                                <path d="M19.2297 8.14C18.9897 7.89 18.6597 7.75 18.3197 7.75H5.67975C5.33975 7.75 4.99975 7.89 4.76975 8.14C4.53975 8.39 4.40975 8.73 4.42975 9.08L5.04975 19.34C5.15975 20.86 5.29975 22.76 8.78975 22.76H15.2097C18.6997 22.76 18.8398 20.87 18.9497 19.34L19.5697 9.09C19.5897 8.73 19.4597 8.39 19.2297 8.14ZM13.6597 17.75H10.3297C9.91975 17.75 9.57975 17.41 9.57975 17C9.57975 16.59 9.91975 16.25 10.3297 16.25H13.6597C14.0697 16.25 14.4097 16.59 14.4097 17C14.4097 17.41 14.0697 17.75 13.6597 17.75ZM14.4997 13.75H9.49975C9.08975 13.75 8.74975 13.41 8.74975 13C8.74975 12.59 9.08975 12.25 9.49975 12.25H14.4997C14.9097 12.25 15.2497 12.59 15.2497 13C15.2497 13.41 14.9097 13.75 14.4997 13.75Z" fill="#ffffffbe"/>
                                                            </svg>
                                                        </div>
                                                    </Tooltip> 
                                                    
                                                    { singleProject.status ?
                                                        <Tooltip title="عدم تفعيل المشروع ">
                                                            <div onClick={()=>toggleActivate(singleProject.slug)} className='blogIcon d-flex align-items-center justify-content-center' style={{"cursor" : "pointer"}}>
                                                                <FontAwesomeIcon icon={faPause}/>
                                                            </div>
                                                        </Tooltip>
                                                        :
                                                        <Tooltip title=" تفعيل المشروع ">
                                                            <div onClick={()=>toggleActivate(singleProject.slug)} className='blogIcon ms-1 d-flex align-items-center justify-content-center' style={{"cursor" : "pointer"}}>
                                                                <FontAwesomeIcon icon={faPlay}/>
                                                            </div>
                                                        </Tooltip>
                                                    } 
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div className={`${style.project_info}`}>
                                            <h6>{singleProject.name.substring(0, 100)} {singleProject.name.length > 100 ? '...' :''}</h6>
                                            <div>
                                                <div className='d-flex align-items-center mb-2'> 
                                                    <BiCategory style={{"width" : "21px" , "height" : "21px"}}/>
                                                    <p className='mb-0 me-2'>{singleProject.type ? singleProject.type.name : ""}</p>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <GoLocation style={{"width" : "21px" , "height" : "21px"}}/>
                                                    <p className='mb-0 me-2'>المملكة العربية السعودية / {singleProject.city ? singleProject.city.name : ""}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) : ""
                        }
                    </div>
                :
                    <div className='noDataClass d-flex justify-content-center align-items-center'>لا توجد مشاريع</div>
                }

                {/** delete project modal **/}
                <div className="modal fade" id='deleteProject' tabIndex={-1} aria-labelledby='deleteProjectLabel' aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className={`modal-content ${style.block_modal_content}`}>
                            <div className={`modal-header border-bottom-0 ${style.block_modal_header}`}>
                                <h5 className="modal-title" id='deleteProjectLabel'>هل تريد حذف هذا المشروع ؟</h5>
                                <button type="button" className="btn-close m-0" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className={`modal-footer border-top-0 pt-0 w-100 ${style.block_modal_footer}`}>
                                <button type="button" className={`${style.cancelBtn}`} data-bs-dismiss="modal">الغاء</button>
                                <button onClick={deleteProject} type="button" className={`${style.blockBtn}`} data-bs-dismiss="modal">تاكيد</button>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </section>
    )
}
