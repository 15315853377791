import React from 'react'
import { basename, apiHost } from './../../config/config';
import { Link } from 'react-router-dom';
import style from "../Types/Types.module.css";
import { useEffect,useState } from 'react';
// import { useState } from 'react';
// import moment from 'moment';
import Tooltip from "@material-ui/core/Tooltip";
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faPause, faPlay   } from '@fortawesome/free-solid-svg-icons';
import { activatePartner, getAllPartners, removePartner } from '../../api/partnerApi';


export default function Partners() {
    const [allPartners,setallPartners]=useState([]);

    function getPartners(){
        getAllPartners().then((res)=>{
            let partners=res.data.data;
            setallPartners(partners);
        })
    }
    useEffect(()=>{
        getPartners()
    },[])

    function toggleActivate(slug){
        activatePartner(slug).then((res)=>{
            Swal.fire({
                icon: 'success',
                title: res.data.data.message,
                confirmButtonText:'تم',
                showConfirmButton: true,
            }).then(()=>{
                getPartners();
            })
        })
    }
    function deleteHandler(slug){
        Swal.fire({
            title: 'هل انت متأكد ؟',
            text: "لا يمكن التراجع عن هذا الأمر",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText:'الغاء',
            confirmButtonColor: '#F7A116',
            cancelButtonColor: '#1f2937',
            confirmButtonText: 'نعم, حذفه',
          }).then((result) => {
            if (result.isConfirmed) {
                removePartner(slug).then(res => {
                Swal.fire({
                    icon: 'success',
                    text: "تم الحذف بنجاح",
                    timer: 2000,
                    showConfirmButton: false,
                });
                getPartners();
            }).catch(err => {
                Swal.fire({
                    icon: 'error',
                    confirmButtonColor : "#1f2937",
                    text: err.response.data.message,
                    confirmButtonText:'تم'
                }).then(()=>{
                    getPartners();
                })
            })
            }
          })
    }

    return (
        <section style={{ "direction": "rtl" }}>
            <div className='sectionWrapper'>
                <Link to={`${basename}/dashboard/website-settings/partners/add-partner`} className='createBtnContainer' >
                    اضافة  شريك
                </Link>
                {/** displaying data in table **/}
                {allPartners && allPartners.length >0 ?
                      <div className='table-responsive'>

                <table className={`${style.types_table} table table-striped`} dir='rtl'>
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">الأيقونة  </th>
                            <th scope="col">الاسم  </th>
                            <th scope="col">الرابط  </th>
                            {/* <th scope="col">التاريخ</th> */}
                            <th scope="col">تعديل</th>
                            <th scope="col">حذف</th>
                            <th scope="col">تفعيل</th>
                        </tr>
                    </thead>
                    <tbody className={`${style.noBorder}`}>
                        {allPartners && allPartners.map((partner, index) => (
                            <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td><img className='partnerIcon' src={`${apiHost}/${partner.image}`} alt={partner.image}/></td>
                                <td>{partner.title ?? '-'}</td>
                                <td>{partner.link ?? '-'}</td>
                                {/** edit icon **/}
                                <td>
                                    <Link to={`${basename}/dashboard/website-settings/partners/edit-partner/${partner.slug}`} className={`${style.brandsIcon}`}>
                                        <svg width="17" height="17" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.1378 2.98637C14.7152 3.56378 14.7247 4.49698 14.1591 5.08597L8.74224 10.7271C8.46695 11.0137 8.08913 11.1795 7.69176 11.1878L5.41644 11.2355L5.46449 8.94497C5.47264 8.55664 5.63111 8.1866 5.90655 7.91274L11.4436 2.40735C12.0298 1.82442 12.9773 1.82578 13.5619 2.41038L14.1378 2.98637Z" fill="#003260" />
                                            <path opacity="0.3" d="M8.60065 1.33325C8.96884 1.33325 9.26732 1.63173 9.26732 1.99992C9.26732 2.36811 8.96884 2.66659 8.60065 2.66659H4.00065C3.26427 2.66659 2.66732 3.26354 2.66732 3.99992V11.9999C2.66732 12.7363 3.26427 13.3333 4.00065 13.3333H12.0007C12.737 13.3333 13.334 12.7363 13.334 11.9999V8.66659C13.334 8.2984 13.6325 7.99992 14.0007 7.99992C14.3688 7.99992 14.6673 8.2984 14.6673 8.66659V11.9999C14.6673 13.4727 13.4734 14.6666 12.0007 14.6666H4.00065C2.52789 14.6666 1.33398 13.4727 1.33398 11.9999V3.99992C1.33398 2.52716 2.52789 1.33325 4.00065 1.33325H8.60065Z" fill="#1B3C94" />
                                        </svg>
                                    </Link>
                                </td>
                                {/** delete icon **/}
                                <td>
                                    <div onClick={() => deleteHandler(partner.slug)} className={`${style.brandsIcon}`}>
                                        <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.0697 5.23C19.4597 5.07 17.8497 4.95 16.2297 4.86V4.85L16.0097 3.55C15.8597 2.63 15.6397 1.25 13.2997 1.25H10.6797C8.34967 1.25 8.12967 2.57 7.96967 3.54L7.75967 4.82C6.82967 4.88 5.89967 4.94 4.96967 5.03L2.92967 5.23C2.50967 5.27 2.20967 5.64 2.24967 6.05C2.28967 6.46 2.64967 6.76 3.06967 6.72L5.10967 6.52C10.3497 6 15.6297 6.2 20.9297 6.73C20.9597 6.73 20.9797 6.73 21.0097 6.73C21.3897 6.73 21.7197 6.44 21.7597 6.05C21.7897 5.64 21.4897 5.27 21.0697 5.23Z" fill="#02315B" />
                                            <path d="M19.2297 8.14C18.9897 7.89 18.6597 7.75 18.3197 7.75H5.67975C5.33975 7.75 4.99975 7.89 4.76975 8.14C4.53975 8.39 4.40975 8.73 4.42975 9.08L5.04975 19.34C5.15975 20.86 5.29975 22.76 8.78975 22.76H15.2097C18.6997 22.76 18.8398 20.87 18.9497 19.34L19.5697 9.09C19.5897 8.73 19.4597 8.39 19.2297 8.14ZM13.6597 17.75H10.3297C9.91975 17.75 9.57975 17.41 9.57975 17C9.57975 16.59 9.91975 16.25 10.3297 16.25H13.6597C14.0697 16.25 14.4097 16.59 14.4097 17C14.4097 17.41 14.0697 17.75 13.6597 17.75ZM14.4997 13.75H9.49975C9.08975 13.75 8.74975 13.41 8.74975 13C8.74975 12.59 9.08975 12.25 9.49975 12.25H14.4997C14.9097 12.25 15.2497 12.59 15.2497 13C15.2497 13.41 14.9097 13.75 14.4997 13.75Z" fill="#02315B" />
                                        </svg>
                                    </div>
                                </td>
                                <td>
                                { partner.active ?
                                        <Tooltip title="تعطيل العنصر">
                                            <div onClick={()=>toggleActivate(partner.slug)} className={`${style.brandsIcon} toggleIcon`} >
                                                <FontAwesomeIcon icon={faPause}/>
                                            </div>
                                        </Tooltip>
                                        :
                                        <Tooltip title="تنشيط العنصر">
                                            <div onClick={()=>toggleActivate(partner.slug)} className={`${style.brandsIcon} toggleIcon`} >
                                                <FontAwesomeIcon icon={faPlay}/>
                                            </div>
                                        </Tooltip>
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
</div>
                 :<div className='noDataClass d-flex justify-content-center align-items-center'>لا توجد بيانات</div>
                }
            </div>
        </section>
    )
}
