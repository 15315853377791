import React from 'react';
import logo from '../../Images/logo1.svg';

export default function Overview() {
    return (
        <section style={{ "direction": "rtl" }}>
            <div className='sectionWrapper overView'>
                <h1>لوحة تحكم ياسر المحمادي للمقاولات</h1>
                <img src={logo} alt='ياسر المحمادي'/>
            </div>
        </section>
    )
}
