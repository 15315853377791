import { apiUrl } from '../config/config.js';
import { http } from './../config/http';

//get contacts
export async function getAllcontacts(){
    return await http.get(`${apiUrl}/admin/contact-us`);
}
//get contacts
export async function getcontactDetails(slug){
    return await http.get(`${apiUrl}/admin/contact-us/${slug}`);
}
//read contact message
export async function readContact(slug){
    return await http.post(`${apiUrl}/admin/contact-us/${slug}/read`);
}